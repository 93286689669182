import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert a number of seconds into a time listing
 * Usage:
 *   timeInSeconds | timeDuration
 * Example:
 *   {{ 15 | timeDuration }}
 *   formats to: 0:15
*/
@Pipe({name: 'timeDuration'})
export class TimeDurationPipe implements PipeTransform {
  transform(timeInSeconds: number): number {
    // Ensure that the passed in data is a number
    if (isNaN(timeInSeconds) || timeInSeconds < 1) {
        // If the data is not a number or is less than one (thus being meaningless) return it unmodified.
        return timeInSeconds;
    } else {
        let moment = require('moment-timezone');
        let momentDurationFormatSetup = require('moment-duration-format');

        momentDurationFormatSetup(moment);

        return timeInSeconds < 60
            ? '0:' + moment.duration(timeInSeconds, 'seconds').format('ss')
            : moment.duration(timeInSeconds, 'seconds').format('HH:mm:ss');
    }
  }
}